import { Injectable } from '@angular/core';
import { EventJoinerModel } from '@index/models/event-joiner';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';
import { AutoLoadService } from '@sentinels/services/core/auto-load.service';
import { selectEventByid } from '@sentinels/state/features/events/selectors';
import { joinerLoadList } from '@sentinels/state/features/joiners/actions';
import { selectJoinersByEventId } from '@sentinels/state/features/joiners/selectors';
import { unregisteredUserLoadOne } from '@sentinels/state/features/unregistered-user/actions';
import { unregisteredUserReducer } from '@sentinels/state/features/unregistered-user/reducers';
import { selectUnregisteredUserByUid } from '@sentinels/state/features/unregistered-user/selectors';
import { userLoadOne } from '@sentinels/state/features/user/actions';
import { userReducer } from '@sentinels/state/features/user/reducers';
import { selectUserByUid } from '@sentinels/state/features/user/selectors';
import { APP_STATE, eventLoadOne, eventReducer } from '@sentinels/state/public-api';
import { navLayoutReducer } from '@shared/layouts/nav-bar-layout/state/reducers';
import { Observable } from 'rxjs';

import { eventInfoReducer } from '../reducers';

@Injectable({ providedIn: 'root' })
export class EventInfoLoaderService extends AutoLoadService {
  constructor(
    public override readonly store: Store<APP_STATE>,
  ) {
   super(store);
   this.store.addReducer('eventInfoState', eventInfoReducer);
   this.store.addReducer('event', eventReducer);
   this.store.addReducer('user', userReducer);
   this.store.addReducer('unregisteredUser', unregisteredUserReducer);
   this.store.addReducer('navLayout', navLayoutReducer);
  }

  /**
   * Retrieves event by ID.
   * @param {string} eventId - ID of the event.
   * @return {Observable<GthEventItemModel>} Observable of GthEventItemModel.
   */
  getEvent(eventId: string): Observable<GthEventItemModel> {
    return this.autoLoadIfNotFound(
      selectEventByid(eventId),
      eventLoadOne({ id: eventId }),
    );
  }

  /**
   * Retrieves joiners of an event.
   * @param {string} eventId - ID of the event.
   * @return {Observable<EventJoinerModel[]>} Observable of joiners.
   */
  getJoiners(eventId: string): Observable<EventJoinerModel[]> {
    return this.autoLoadIfNotFound(
      selectJoinersByEventId(eventId),
      joinerLoadList({ eventId }),
    );
  }

  /**
   * Retrieves user by ID.
   * @param {string} userId - ID of the user.
   * @return {Observable<GthUserModel>} Observable of user.
   */
  getUser(userId: string): Observable<GthUserModel> {
    return this.autoLoadIfNotFound(
      selectUserByUid(userId),
      userLoadOne({ uid: userId }),
    );
  }

  getUnregisteredUser(userId: string): Observable<GthUnregisteredUserModel> {
    return this.autoLoadIfNotFound(
      selectUnregisteredUserByUid(userId),
      unregisteredUserLoadOne({ uid: userId }),
    );
  }
}
