import { createReducer, on } from '@ngrx/store';

import * as UserActions from './actions';
import { UserState } from './state';

export const initialUserState: UserState = {
  users: {},
};

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.userLoadOneSuccess, (state, { user }) => {
     return {
        ...state,
        users: {
          ...state.users,
          [user.uid]: user,
        },
      };
    }),
  on(UserActions.userLoadListSuccess, (state, { users }) => {
    const updatedUsers = users.reduce((acc, curr) => ({
      ...acc,
      [curr.uid]: curr,
    }), {});
    return {
      ...state,
      users: {
        ...state.users,
        ...updatedUsers,
      },
    };
  }),
);
